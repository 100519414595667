.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 56px;
	padding: 0;
	background: #673AB7;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	z-index: 50;
}

.header h1 {
	float: left;
	margin: 0;
	padding: 0 15px;
	font-size: 24px;
	line-height: 56px;
	font-weight: 400;
	color: #FFF;
}

.header nav {
	float: right;
	font-size: 100%;
}

.header nav a {
	display: inline-block;
	height: 56px;
	line-height: 56px;
	padding: 0 15px;
	min-width: 50px;
	text-align: center;
	background: rgba(255,255,255,0);
	text-decoration: none;
	color: #FFF;
	will-change: background-color;
}

.header nav a:hover,
.header nav a:active {
	background: rgba(0,0,0,0.2);
}

.header nav a.active {
	background: rgba(0,0,0,0.4);
}


@import 'https://fonts.googleapis.com/css?family=Open+Sans';

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	font-family: 'Open Sans', sans-serif;
	line-height: 1.75em;
	font-size: 16px;
    background-color: #3d4149;
	color: #aaa;
}

.simple-container {
	max-width: 675px;
	margin: 0 auto;
	padding-top: 70px;
	padding-bottom: 20px;
}

.simple-print {
	fill: white;
	stroke: white;
}
.simple-print svg {
	height: 100%;
}

.simple-close {
	color: white;
	border-color: white;
}

.simple-ext-info {
	border-top: 1px solid #aaa;
}

p {
	font-size: 16px;
}

h1 {
	font-size: 30px;
	line-height: 34px;
}

h2 {
	font-size: 20px;
	line-height: 25px;
}

h3 {
	font-size: 16px;
	line-height: 27px;
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #D8D8D8;
	border-top: 1px solid #D8D8D8;
}

hr {
	height: 1px;
	background-color: #d8d8d8;
	border: none;
	width: 100%;
	margin: 0px;
}

a[href] {
	color: white;
}

a[href]:hover {
	color: #3ba0e6;
}

img {
	max-width: 100%;
}

li {
	line-height: 1.5em;
}

aside,
[class *= "sidebar"],
[id *= "sidebar"] {
	max-width: 90%;
	margin: 0 auto;
	border: 1px solid lightgrey;
	padding: 5px 15px;
}

@media (min-width: 1921px) {
	body {
		font-size: 18px;
	}
}